import decodeJWTToken from 'authorization-module/helpers/decodeJWTToken'
import config from 'const/config'
import endpoints from 'const/apiEndpoints'
import api from 'services'
import featureFlags from 'const/featureFlags'

let utagLoadCounter = 0

const addUtagScript = () => {
  const utagScript = document.createElement('script')
  utagScript.src = config.UTAG_URL
  utagScript.type = 'text/javascript'
  utagScript.async = true
  document.head.append(utagScript)
}

export const loadUtagScript = async () => {
  if (utagLoadCounter < 1) {
    utagLoadCounter = 1
    if (!featureFlags.isShowCookieSetting) {
      addUtagScript()
      return
    }
    const idToken = sessionStorage.getItem('idToken')
    const { subject } = decodeJWTToken(idToken)
    let token: string

    if (subject) {
      try {
        const { data } = await api.get(endpoints.JWT_TOKEN)
        token = data.token
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error)
      } finally {
        window.OneTrust = {
          dataSubjectParams: {
            id: subject,
            isAnonymous: false,
            token
          }
        }
        addUtagScript()
      }
    } else {
      window.OneTrust = {
        dataSubjectParams: {
          id: undefined,
          isAnonymous: false,
          token: undefined
        }
      }
      addUtagScript()
    }
  }
}
