import { format, parse } from 'date-fns'

const DATE_FORMAT_YYYYMMDD = 'yyyy-MM-dd'
const DATE_FORMAT_MMMD = 'MMM d'
const DATE_FORMAT_MMMDY = 'MMM d, Y'

/**
 * Formats 'yyyy-MM-dd' date to a Date object
 */
export const formatDateStringToObject = (dateString: string): Date => {
  const dateWithoutTimezone = dateString.substring(0, 10)
  return parse(dateWithoutTimezone, DATE_FORMAT_YYYYMMDD, new Date())
}

/**
 * Formats Date String, to a display string: 'MMM d'
 */
export const formatDateString = (date: string): string => {
  if (!date) return ''
  return format(formatDateStringToObject(date), DATE_FORMAT_MMMD)
}

/**
 * Formats Date Object to a display string: 'MMM d'
 */
export const formatDateObject = (date: Date): string => {
  const dateWithoutTimezone = new Date(
    date.valueOf() + date.getTimezoneOffset() * 60000
  )
  return format(dateWithoutTimezone, DATE_FORMAT_MMMD)
}

/**
 * Formats 'yyyy-MM-dd' date, or Date Object, to a display string: 'MMM d, Y'
 */
export const formatDisplayDate = (date: string | Date): string => {
  if (!date) return ''

  if (typeof date === 'string') {
    return format(formatDateStringToObject(date), DATE_FORMAT_MMMDY)
  }
  return format(date, DATE_FORMAT_MMMDY)
}

/**
 * Formats 'yyyy-MM-dd' date to a display day: '1st, 2nd, 3rd...'
 */
export const formatDisplayDay = (dateString: string): string => {
  return format(formatDateStringToObject(dateString), 'do')
}
