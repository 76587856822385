import PageContainer from 'pages/subcomponents'
import styled from 'styled-components'
import query from 'syf-component-library/const/mediaQueries'
import Button from 'syf-component-library/ui/atoms/Button'
import Inset from 'syf-component-library/ui/spacing/Inset'

export const Container = styled(PageContainer)`
  margin: 10px auto;

  @media ${query.greaterThanMedium} {
    max-width: 1200px;
  }

  * {
    box-sizing: border-box;
  }
`

export const LogoutTitle = styled(Inset)`
  color: ${({ theme }) => theme.white};
  width: 100%;
`

export const InnerContainer = styled.div`
  text-align: center;
  width: 100%;
  position: relative;

  @media ${query.greaterThanMedium} {
    width: 768px;
  }
`

export const LoginButton = styled(Button)`
  margin: 0 auto;
  width: 225px;
`

export const LogoutHeader = styled.div`
  background-color: ${({ theme }) => theme.yellow};
  height: 232px;
  width: 100%;

  @media ${query.lessThanMedium} {
    height: 166px;
  }

  img {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    max-width: 100%;

    @media ${query.lessThanMedium} {
      height: 178px;
    }

    @media ${query.greaterThanLarge} {
      height: 256px;
    }
  }
`
