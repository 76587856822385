import {
  QueryKey,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import getConfig from 'helpers/getApiConfig'
import {
  useAccountActivityQuery,
  useAccountFeaturesQuery,
  useAccountSummaryV2Query as useAccountSummaryQuery,
  useAutoRegisterMutation,
  useAutopayStatusQuery,
  useCreditAccountsQuery
} from 'syf-api'
import { useAllAccountSummaryQuery } from 'syf-api/allAccountSummary'
import HTTPError from 'syf-js-utilities/typings/HTTPError'
import {
  AccountActivityGetResponse,
  AccountFeaturesGetResponse,
  AccountSummaryGetResponse,
  AutoRegisterPutResponse,
  AutopayStatusGetResponse,
  CreditAccountsGetResponse
} from 'syf-typings'
import { Account } from 'syf-typings/creditAccounts/creditAccounts'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type QueryOptions = UseQueryOptions<any, any, any, QueryKey>
export interface AllAccountSummaryGetResponse
  extends AccountSummaryGetResponse {
  accountId: string
}

// TODO: Remove this once accessToRewardsMfe is added in syf-typings
export type AccountFeaturesGetResponseUpdated = AccountFeaturesGetResponse & {
  rewardDetails?: {
    accessToRewardsMfe?: boolean
  }
}

const useCreditAccounts = (
  queryOptions?: QueryOptions
): UseQueryResult<CreditAccountsGetResponse, HTTPError> => {
  const accessToken = sessionStorage.getItem('accessToken')
  const isUsingMockData = Boolean(sessionStorage.getItem('mockData'))

  const enabled = isUsingMockData || Boolean(accessToken)
  const apiConfig = getConfig(enabled, queryOptions)

  return useCreditAccountsQuery({
    keyProps: undefined,
    ...apiConfig
  })
}

const useActivity = (
  accountId: string
): UseQueryResult<AccountActivityGetResponse, HTTPError> => {
  const apiConfig = getConfig(Boolean(accountId))

  return useAccountActivityQuery({
    keyProps: { accountId, accountIdType: 'guid' },
    ...apiConfig
  })
}

const useAccountFeatures = (
  accountId: string
): UseQueryResult<AccountFeaturesGetResponseUpdated, HTTPError> => {
  const apiConfig = getConfig(Boolean(accountId))

  return useAccountFeaturesQuery({
    keyProps: { accountId, accountIdType: 'guid' },
    ...apiConfig
  })
}

const useAccountSummary = (
  accountId: string
): UseQueryResult<AccountSummaryGetResponse, HTTPError> => {
  const apiConfig = getConfig(Boolean(accountId))

  return useAccountSummaryQuery({
    keyProps: { accountId, accountIdType: 'guid' },
    ...apiConfig
  })
}

const useAutopayStatus = (
  accountId: string
): UseQueryResult<AutopayStatusGetResponse, HTTPError> => {
  const apiConfig = getConfig(Boolean(accountId))

  return useAutopayStatusQuery({
    keyProps: { accountId, accountIdType: 'guid' },
    ...apiConfig
  })
}

const useAllAccountSummary = (
  accounts: Account[]
): UseQueryResult<AllAccountSummaryGetResponse>[] => {
  const keyProps = accounts.map((account) => {
    return {
      accountId: account.info['cipher.accountId'],
      accountIdType: account?.info?.accountIdType
    }
  })

  const apiConfig = getConfig(Boolean(accounts.length))
  return useAllAccountSummaryQuery({
    keyProps,
    ...apiConfig
  })
}

const useAutoRegisterUpdate =
  (): UseMutationResult<AutoRegisterPutResponse> => {
    const enabled =
      Boolean(sessionStorage.getItem('accessToken')) ||
      Boolean(sessionStorage.getItem('mockData'))
    const apiConfig = getConfig(enabled)

    return useAutoRegisterMutation({
      keyProps: {},
      ...apiConfig
    })
  }

export {
  useAccountFeatures,
  useAccountSummary,
  useActivity,
  useAllAccountSummary,
  useAutopayStatus,
  useAutoRegisterUpdate,
  useCreditAccounts
}
