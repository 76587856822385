import { ReactElement } from 'react'

import SyfAlerts from 'alerts-mfe'
import { mfeConfig } from 'const/mfeConfig'
import useNavigateOnRedirect from 'hooks/useNavigateOnRedirect'
import useProfileNavigate from 'hooks/useProfileNavigate'

const Alerts = (): ReactElement => {
  const { handleProfileClick } = useProfileNavigate()

  useNavigateOnRedirect()

  return (
    <SyfAlerts
      config={mfeConfig}
      alertType="all"
      handleProfileClick={handleProfileClick}
      platform="digital-servicing"
    />
  )
}

export default Alerts
