import config from 'const/config'
import queryClient from 'const/queryClient'
import { QueryOptions } from 'const/syfApiQueries'
import { MFEConfig } from 'account-details-mfe'
import getLanguageFromStorage from 'syf-js-utilities/helpers/getLanguageFromStorage'

interface ApiConfig {
  config: MFEConfig
  queryOptions?: QueryOptions
}

const getApiConfig = (
  enabled: boolean,
  additionalQueryOptions?: QueryOptions
): ApiConfig => {
  const lang = getLanguageFromStorage()
  return {
    config: {
      apiErrorCallback: (err) => err,
      hosts: {
        apigee: new URL(config.API_HOST),
        assets: new URL(config.ASSETS_HOST)
      },
      lang
    },

    queryOptions: {
      ...queryClient.getQueryDefaults(),
      enabled,
      ...additionalQueryOptions
    }
  }
}

export default getApiConfig
