import { consumerTheme } from 'syf-component-library/defaultTheme'

const { gradientStart, gradientEnd, valueTextColor } = consumerTheme.meter

export const colors = {
  black: '#333333',
  blue: '#58a7af',
  brightGray: '#3B434F',
  cyanBlue: '#34657f',
  darkBlue: '#3c777c',
  ebonyClay: '#1F2937',
  eerieBlack: '#232323',
  darkGrey: '#9E9E9E',
  error: '#C63527',
  green: '#3e8529',
  grey: '#94969a',
  info: '#0072AD',
  lightBlue: '#e2e7ec',
  lighterBlue: '#0472ae',
  lightCyanBlue: '#F9FAFB',
  lighterGrey: '#D8D8D8',
  lightestGrey: '#F6F6F6',
  lightGrey: '#E0E0E0',
  mediumGrey: '#F8F8F8',
  athensGray: '#f3f4f6',
  lightGreyBorder: '#e5e7eb',
  navyBlue: '#274e61',
  orange: '#d76b00',
  silver: '#767676',
  success: '#3E8529',
  warning: '#FEB600',
  white: '#FFFFFF',
  yellow: '#fbc600'
}

const components = {
  body: {
    background: 'linear-gradient(to left, #274C60 0%, #162C37 100%)',
    backgroundColor: '#f6f6f6',
    text: '#333333'
  },
  button: {
    background: '#34657f',
    primary: {
      text: '#FFFFFF',
      hover: '#0072ad'
    },
    secondary: {
      text: '#34657f',
      hover: '#ebf0f2'
    }
  },
  chip: {
    hover: '#f0f3f5',
    text: '#333333'
  },
  icon: '#34657f',
  link: '#34657f',
  footer: {
    background: '#FFFFFF',
    text: '#333333',
    link: '#35657f'
  },
  checkbox: {
    background: '#34657f',
    font: '#FFFFFF'
  },
  navigation: {
    activeTabText: '#34657F',
    activeTabBorder: '#34657F'
  },
  systemNavigation: {
    background: '#ffffff'
  },
  modal: {
    background: '#ffffff',
    headerBackground: '#f6f6f6'
  },
  hoverBackground: '#f0f3f5',
  datepicker: {
    legend: {
      selectedDate: {
        fill: '#34657f',
        border: '#34657f',
        text: '#ffffff'
      },
      dueDate: {
        fill: '#ffffff',
        border: '#000000',
        text: '#000000'
      },
      lateFeeDate: {
        fill: '#757575',
        border: '#757575',
        text: '#ffffff'
      }
    }
  },
  field: {
    icon: '#34657f',
    focus: '#34657f',
    label: '#34657f'
  },
  tooltip: {
    backgroundColor: '#FFFFFF'
  },
  meter: {
    valueTextColor,
    gradientStart,
    gradientEnd
  },
  progressBar: {
    complete: '#3e8529',
    error: '#c63527',
    inProgress: '#0072ad',
    background: '#e9eaeb'
  }
}

const theme = {
  ...components,
  ...colors
}

export type DefaultThemeType = typeof theme

export default theme
