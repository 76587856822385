import { ReactElement, Suspense, lazy, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { updateModalStatus } from 'redux/modals/actions'
import { RootState } from 'redux/store'

import { mfeConfig } from 'const/mfeConfig'
import { rewardsModalContainerId } from 'const/modalContainerId'
import routes from 'const/routes'
import { getLanguageFromStorage } from 'helpers'
import i18n from 'strings/i18n'
import {
  ModalHeader,
  SlideInModalPortal
} from 'syf-component-library/ui/patterns/SlideInModalPortal'
import Loader from 'authorization-module/ui/atoms/Loader'
import refreshChunk from 'helpers/refreshChunk'

const RewardsModalContent = lazy(() =>
  refreshChunk(
    () =>
      import('rewards-mfe').then((module) => ({
        default: module.RewardsModalContent
      })),
    'rewards-mfe'
  )
)

const RewardsModal = (): ReactElement => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const headerRef = useRef(null)

  const { selectedAccount, modalId, modalTriggerId } = useSelector(
    (state: RootState) => ({
      selectedAccount: state.modals.selectedAccount,
      modalId: state.modals.modalId,
      modalTriggerId: state.modals.modalTriggerId
    })
  )

  const handleCloseModal = () => {
    dispatch(updateModalStatus('', '', true))
  }

  const goToRewardsPage = () => {
    dispatch(updateModalStatus(''))
    setTimeout(() => {
      navigate(routes.REWARDS)
    }, 400)
  }

  const title = i18n({ rewardsModal: 'modalTitle' }) as string

  return (
    <SlideInModalPortal
      id="rewards-slide-modal"
      containerId={rewardsModalContainerId(selectedAccount)}
      isOpen={modalId === 'Rewards'}
      modalTriggerId={modalTriggerId}
      aria-labelledby={title}
      escapeModalHandler={handleCloseModal}
      header={
        <ModalHeader
          headerRef={headerRef}
          closeModal={handleCloseModal}
          content={title}
        />
      }
    >
      <Suspense fallback={<Loader />}>
        <RewardsModalContent
          config={{ ...mfeConfig, lang: getLanguageFromStorage() }}
          handleClose={handleCloseModal}
          goToRewardsPage={goToRewardsPage}
        />
      </Suspense>
    </SlideInModalPortal>
  )
}
export default RewardsModal
