const routes = {
  HOME: '/',
  CALLBACK: '/callback',
  DISPUTES: '/activity/disputes',
  LOGOUT: '/logout',
  ERROR: '/error',
  REWARDS_ERROR: '/rewards-error',
  ALERTS: '/alerts',
  ACTIVITY: '/activity',
  CREDIT_BALANCE_REFUND: '/refund',
  PROFILE: '/profile',
  MARKETING_PRIVACY: '/marketing-privacy',
  ACCOUNT_SERVICES: '/account-services',
  REWARDS: '/rewards'
} as const

type Valueof<T> = T[keyof T]

export type Routes = Valueof<typeof routes>

export type RouteKeys = keyof typeof routes

export default routes
