import { ReactElement } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { updateModalStatus } from 'redux/modals/actions'
import { RootState } from 'redux/store'

import CreditLimitIncrease from 'account-services-mfe'
import { mfeConfig } from 'const/mfeConfig'
import triggerAnalyticsEvent from 'helpers/triggerAnalyticsEvent'

const CreditLimitIncreaseModal = (): ReactElement => {
  const dispatch = useDispatch()

  const { selectedAccount, modalId } = useSelector(
    (state: RootState) => ({
      selectedAccount:
        state.modals.selectedAccount ||
        sessionStorage.getItem('selectedAccountId'),
      modalId: state.modals.modalId
    }),
    shallowEqual
  )

  const handleCloseModal = () => {
    dispatch(updateModalStatus('', '', true))
  }

  return (
    <CreditLimitIncrease
      config={mfeConfig}
      selectedAccountId={selectedAccount}
      handleClose={handleCloseModal}
      isOpen={modalId === 'CreditLimitIncrease'}
      triggerAnalyticsEvent={triggerAnalyticsEvent}
    />
  )
}

export default CreditLimitIncreaseModal
