import { ReactElement, Suspense, lazy } from 'react'

import { mfeConfig } from 'const/mfeConfig'

import useNavigateOnRedirect from 'hooks/useNavigateOnRedirect'
import Loader from 'authorization-module/ui/atoms/Loader'

import { getLanguageFromStorage } from 'syf-js-utilities'
import refreshChunk from 'helpers/refreshChunk'

const RewardsPage = lazy(() =>
  refreshChunk(
    () =>
      import('rewards-mfe').then((module) => ({
        default: module.RewardsPage
      })),
    'rewards-mfe'
  )
)

const Rewards = (): ReactElement => {
  useNavigateOnRedirect()

  const currentLanguage = getLanguageFromStorage()

  return (
    <Suspense fallback={<Loader />}>
      <RewardsPage config={{ ...mfeConfig, lang: currentLanguage }} />
    </Suspense>
  )
}

export default Rewards
