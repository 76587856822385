export const SETPAY = 'INSTALLMENT_LOAN'
export const DUAL_CARD = 'DUAL_CARD'
export const RC = 'RETAIL_CARD'
export const RC_ACCOUNT_URL_PATH = '/AccountSummary/servicehandover.action'
export const PSCC = 'PAYMENT_SOLUTIONS_CARECREDIT'
export const PSCC_ACCOUNT_URL_PATH =
  'handoffaction_mfa_continue.do?subActionID=1009'
export const PSCC_REGISTER_ACCOUNT_URL_PATH =
  'registeraction.do?subActionID=1000'
export const AMAZON_SECURED_CARD = 'Amazon Secured Card'
export const AMAZON_PRIME_SECURED_CARD = 'Amazon Prime Secured Card'
