import { ReactElement } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { RootState } from 'redux/store'

import CreditBalanceRefund from 'cbr-mfe'
import { mfeConfig } from 'const/mfeConfig'
import routes from 'const/routes'
import authenticationRedirect from 'helpers/authenticationRedirect'
import triggerAnalyticsEvent from 'helpers/triggerAnalyticsEvent'
import useNavigateOnRedirect from 'hooks/useNavigateOnRedirect'
import styled from 'styled-components'
import Inset from 'syf-component-library/ui/spacing/Inset/Inset'
import useProfileNavigate from 'hooks/useProfileNavigate'

const CreditBalanceRefundWrapper = styled(Inset)`
  max-width: 1200px;
  margin: auto;
`

const CreditBalanceRefundPage = (): ReactElement => {
  const navigate = useNavigate()
  const { handleProfileClick } = useProfileNavigate()

  const { selectedAccount } = useSelector(
    (state: RootState) => ({
      selectedAccount:
        state.modals.selectedAccount ||
        sessionStorage.getItem('selectedAccountId')
    }),
    shallowEqual
  )

  const handleBackToDashboard = () => {
    navigate(routes.HOME)
  }

  useNavigateOnRedirect()

  return (
    <CreditBalanceRefundWrapper>
      <CreditBalanceRefund
        config={mfeConfig}
        accountId={selectedAccount}
        backToAccountManagerHandler={handleBackToDashboard}
        triggerAnalyticsEvent={triggerAnalyticsEvent}
        authenticationRedirect={authenticationRedirect}
        handleProfileClick={handleProfileClick}
      />
    </CreditBalanceRefundWrapper>
  )
}

export default CreditBalanceRefundPage
