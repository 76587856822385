import { ReactElement, RefObject, useRef } from 'react'

import featureFlags from 'const/featureFlags'
import {
  useAccountSummary,
  useActivity,
  useAutopayStatus,
  useCreditAccounts
} from 'const/syfApiQueries'
import findAccount from 'helpers/findAccount'
import isLoanProductCard from 'helpers/isLoanProductCard'
import getNotifications from 'notifications/helpers/getNotifications'

import AccountCard from './AccountCard'
import CardLayout from './layouts/CardLayout'

export interface CardProps {
  accountId: string
  cardIndex: number
}

const Card = ({ accountId, cardIndex }: CardProps): ReactElement => {
  const focusRef: RefObject<HTMLDivElement> = useRef(null)
  const { data: { accounts = [] } = {} } = useCreditAccounts()
  const {
    data: accountSummary,
    isLoading: isAccountSummaryLoading,
    isError: isAccountSummaryError
  } = useAccountSummary(accountId) || {}

  const { data: { activity = [] } = {} } = useActivity(accountId) || {}
  const { data: autopayStatus } = useAutopayStatus(accountId) || {}

  const account = findAccount(accounts, accountId)
  const enrollmentDetails = autopayStatus?.enrollmentDetails
  const { billing, credit, conditions, installment, product } =
    accountSummary || {}
  const { type: productType, clientId = '' } = product || {}
  const isRegistered = account?.registration?.registered

  const notificationAccountDetails = {
    activity,
    billing,
    conditions,
    credit,
    enrollmentDetails,
    installment,
    isRegistered,
    product: productType
  }

  const notificationList = getNotifications(notificationAccountDetails)

  // Auto-registration is failed if transferable is true and we want to show the error message.
  const isError = isAccountSummaryError || account?.registration?.transferable

  if (isLoanProductCard(productType) && featureFlags.isAccountCardMfeEnabled) {
    return (
      <AccountCard
        accountId={accountId}
        clientId={clientId}
        productType={productType}
      />
    )
  }

  return (
    <CardLayout
      accountSummary={accountSummary}
      account={account}
      cardIndex={cardIndex}
      isAccountSummaryLoading={isAccountSummaryLoading}
      isError={isError}
      notificationList={notificationList}
      ref={focusRef}
    />
  )
}

export default Card
