import { ReactElement } from 'react'

const strings = {
  button: {
    accountManager: 'ACCOUNT MANAGER',
    alertsTooltip: 'Manage enrollment in Alerts',
    clickHere: 'click here',
    makeAPayment: 'Make a Payment',
    paymentOptions: 'View Payment Options',
    paymentPlan: 'View Payment Plan',
    reload: 'reload',
    signOut: 'Sign Out',
    activity: 'Activity',
    back: 'BACK',
    goBackHome: 'Go Back Home',
    languageText: 'Español',
    close: 'close',
    agreeAndContinue: 'AGREE & CONTINUE',
    welcomeConfirmation: 'GOT IT!',
    registerAccount: 'REGISTER ACCOUNT',
    viewMyAccount: 'View My Account',
    statements: 'Statements',
    rewards: 'Rewards',
    accountDetails: 'Details',
    viewAllActivity: 'View All Activity',
    activateCard: 'Activate Card',
    profileManagement: 'Manage Profile',
    marketingSetting: 'Marketing & Privacy Settings',
    more: 'More',
    accountManagerReturn: 'RETURN TO ACCOUNT MANAGER',
    creditBalanceRefund: 'Manage Your Credit Balance',
    crossBorderOpenTab: 'Open Tab',
    crossBorderCancelTab: 'Cancel',
    creditLimitIncrease: 'Credit limit increase',
    changePaymentDueDate: 'Change payment due date',
    activateACard: 'Activate a card',
    freezeCard: 'Freeze card',
    replaceYourCard: 'Replace your card',
    reportLostStolenCard: 'Report lost or stolen card',
    manageTravelNotifications: 'Manage travel notifications',
    createChangePIN: 'Create/change PIN'
  },
  labels: {
    accountActions: 'Account Actions',
    accountEndingIn: 'Account ending in',
    accountsServices: 'Account Services',
    cardActions: 'Card Actions',
    activityMenu: 'Activity',
    security: 'Security',
    loanEndingIn: 'Loan ending in',
    dueDate: 'Payment Due Date',
    monthlyPaymentAmount: 'Monthly Payment Amount',
    totalMinimumPayment: 'Total Minimum Payment',
    noPaymentDue: 'No Payment Due',
    forHelp: 'for help.',
    creditLimit: 'Credit Limit',
    currentBalance: 'Current Balance',
    totalPaid: 'Total Paid',
    availableToSpend: 'Available to spend',
    accountSnapshot: 'ACCOUNT SNAPSHOT',
    totalRemaining: 'Total Remaining',
    monthlyPayment: 'Monthly Payment',
    currentBalanceTooltip: 'May not reflect recent transactions.',
    unavailable: 'Unavailable',
    recentActivity: 'Recent Activity',
    currentBalanceAlt: 'Current Balance Information',
    loanAmount: 'Loan Amount',
    totalLoanBalance: 'Total Loan Balance',
    nextAmountDue: 'Next Amount Due',
    nextAmountDueTooltip: 'Includes any fees and past due amounts',
    totalLoanBalanceTooltip:
      'The Total Loan Balance may change for various reasons (e.g. payments and returns). If applicable, the Total Loan Balance may increase as items are fulfilled.',
    crossBorderText:
      'The information shared at this link is not part of Synchrony Bank. Please note that because this site you are going to is not controlled by Synchrony Bank, you will be subject to the terms of use and privacy policy of the website you are visiting.'
  },
  autopay: {
    text: 'Autopay',
    on: 'ON',
    off: 'OFF'
  },
  overlayMessages: {
    accountUpgrading: 'Your Account is being Upgraded!',
    attentionRequired: 'Your Attention is Required',
    checkBackForDetails: 'Check back soon to view your account details.',
    careCreditUnavailable: 'CareCredit Rewards are currently unavailable',
    checkBackLater: 'Please check back later',
    contactInfo: 'Please contact customer service at 1-800-542-5593.',
    detailsUnavailable: 'Account Details Unavailable',
    issueWithAccount: 'There is an issue with your account.',
    noInformation:
      'We are having trouble retrieving information for this account.',
    setpayLoanProcessing: 'We’re still processing your SetPay loan details.',
    tryLater: 'Please try again later.',
    activationReady: ({
      displayName
    }: {
      displayName: ReactElement
    }): ReactElement => (
      <>Your new card for {displayName} is ready for activation.</>
    ),
    clickActivateButton: 'Click the button below to get started.'
  },
  errors: {
    littleTrouble:
      "Sorry, we're having a little trouble getting this information.",
    pleaseReload:
      'Please reload the page or try again later. If the problem continues, ',
    pageNotFound:
      'The page you’re looking for either doesn’t exist or can’t be found.',
    sorryPage: "Sorry, we couldn't find that page.",
    weApologize: 'We Apologize',
    technicalDifficulties:
      'We are currently experiencing technical difficulties and are unable to access your account.',
    unableToFindAccount:
      'While your login was successful, we are unable to find the account associated with your credentials.',
    tryAgain: 'Try Again',
    tryAgainLater:
      'Please try again later, or contact customer service for assistance.',
    accountClosedOrBlocked: 'This account may have been closed or blocked.',
    contactCustomerService:
      'Please contact customer service at the number on the back of your card.'
  },
  welcomeInterstitial: {
    welcomeTitle: 'Welcome to your account manager',
    manageAccountSubTitle:
      'A simpler, faster and more convenient way to manage your Synchrony accounts.',
    legalStatement: 'communications agreement',
    note: 'Note:',
    welcomeNote:
      'Your agreement does not change your current statement delivery setting. Your statement delivery settings will not change unless we notify you in the future or you make the changes yourself by logging into your account.',
    eConsentStatement: ({
      legalStatementLink
    }: {
      legalStatementLink: ReactElement
    }): ReactElement => (
      <>By using the account manager, you agree to our {legalStatementLink}.</>
    )
  },
  personalGreeting: {
    morning: 'Good Morning',
    afternoon: 'Good Afternoon',
    evening: 'Good Evening'
  },
  footer: {
    body: 'Your credit accounts are issued by Synchrony Bank.  The Synchrony Bank Privacy Policy governs the use of your credit accounts.  The use of this site is governed by the use of the Synchrony Bank Online Privacy Policy. The content and documents displayed may not all be provided in Spanish.',
    privacyPolicy: 'Online Privacy Policy',
    privacyPolicyLink: 'privacy-policy.html',
    usageAgreement: 'Online Usage Agreement',
    usageAgreementLink: 'terms-of-use.html ',
    fraudProtection: 'Fraud Protection',
    fraudProtectionlink: 'fraud-protection.html',
    accessibility: 'Accessibility',
    accessibilityLink: 'gwas',
    cookieSettings: 'Cookie Settings'
  },
  inactivityModal: {
    modalTitle: 'Session Timeout',
    promptTitle: 'Are you still there?',
    countdownWarning: 'Your online session will expire in',
    continueButton: "I'm still here",
    logOffButton: 'Log Off',
    seconds: 'seconds.'
  },
  rewardsModal: {
    modalTitle: 'Rewards Snapshot'
  },
  logout: {
    pageTitle: 'Logged Out',
    cardTitle: 'Thank you for managing your Synchrony accounts.',
    cardDescription: "You've securely logged out.",
    button: 'LOG BACK IN'
  }
}

export default strings
