import { maxDashboardWidth } from 'const/styles'
import styled from 'styled-components'
import query from 'syf-component-library/const/mediaQueries'
import { spacing } from 'syf-component-library/defaultTheme'
import { Inset } from 'syf-component-library/ui/spacing'

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const DashboardContainer = styled(Inset)`
  width: 100%;

  @media ${query.greaterThanLarge} {
    max-width: ${maxDashboardWidth}px;
  }
`

export const DashboardAccountGrid = styled.div`
  display: flex;
  margin: 0 auto;
  padding-bottom: ${spacing.xlarge}px;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: ${spacing.small}px;

  @media ${query.greaterThanSmall} {
    gap: ${spacing.base}px;
    justify-content: center;
  }

  @media ${query.greaterThanMedium} {
    gap: ${spacing.xlarge}px;
  }
  * {
    box-sizing: border-box;
  }
`
