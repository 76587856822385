import { FC, ReactElement } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'redux/store'

import { mfeConfig } from 'const/mfeConfig'
import authenticationRedirect from 'helpers/authenticationRedirect'
import useNavigateOnRedirect from 'hooks/useNavigateOnRedirect'
import ProfileMFE, { MFEConfig } from 'profile-mfe'
import { SupportedLanguage } from 'syf-js-utilities'

interface ProfileProps {
  currentLanguage: SupportedLanguage
}

const Profile: FC<ProfileProps> = ({ currentLanguage }): ReactElement => {
  const selectedAccount = useSelector(
    (state: RootState) =>
      state.modals.selectedAccount ||
      sessionStorage.getItem('selectedAccountId')
  )

  const profileConfig: MFEConfig = {
    ...mfeConfig,
    lang: currentLanguage
  }

  useNavigateOnRedirect()

  return (
    <ProfileMFE
      config={profileConfig}
      initialAccountId={selectedAccount}
      authenticationRedirect={authenticationRedirect}
    />
  )
}

export default Profile
