const { NODE_ENV } = process.env

export interface FeatureFlagsType {
  isAccountCardMfeEnabled: boolean
  isAccountDetailsEnabled: boolean
  isAccountUpgradingMessageEnabled: boolean
  isAlertsEnabled: boolean
  isAutopayEnabled: boolean
  isAvailableToSpendAnimationEnabled: boolean
  isCardActivationEnabled: boolean
  isCBREnabled: boolean
  isCreditLimitIncreaseEnabled: boolean
  isCobrowseEnabled: boolean
  isDYEnabled: boolean
  isDYScriptEnabled: boolean
  isIvaEnabled: boolean
  isLateFeeDateEnabled: boolean
  isManagePaymentMethodsEnabled: boolean
  isMarketingPrivacySettingEnabled: boolean
  isProfileManagementEnabled: boolean
  isProgressBarEnabled: boolean
  isRewardsDeeplinkEnabled: boolean
  isSavingCheckingEnabled: boolean
  isSecuredInstallmentEnabled: boolean
  isShowCookieSetting: boolean
  isStatementsDeeplinkEnabled: boolean
  isStatementsMfeEnabled: boolean
  isSurflyScriptTagEnabled: boolean
}

const prodFeatureFlags: FeatureFlagsType = {
  isAccountCardMfeEnabled: false,
  isAccountDetailsEnabled: true,
  isAccountUpgradingMessageEnabled: false,
  isAlertsEnabled: true,
  isAutopayEnabled: true,
  isAvailableToSpendAnimationEnabled: false,
  isCardActivationEnabled: false,
  isCBREnabled: true,
  isCreditLimitIncreaseEnabled: false,
  isCobrowseEnabled: false,
  isDYEnabled: false,
  isDYScriptEnabled: false,
  isIvaEnabled: true,
  isLateFeeDateEnabled: false,
  isManagePaymentMethodsEnabled: false,
  isMarketingPrivacySettingEnabled: true,
  isProfileManagementEnabled: false,
  isProgressBarEnabled: false,
  isRewardsDeeplinkEnabled: true,
  isSavingCheckingEnabled: false,
  isSecuredInstallmentEnabled: false,
  isShowCookieSetting: false,
  isStatementsDeeplinkEnabled: true,
  isStatementsMfeEnabled: false,
  isSurflyScriptTagEnabled: true
}

const localFeatureFlags: FeatureFlagsType = {
  isAccountCardMfeEnabled: true,
  isAccountDetailsEnabled: true,
  isAccountUpgradingMessageEnabled: false,
  isAlertsEnabled: true,
  isAutopayEnabled: true,
  isAvailableToSpendAnimationEnabled: true,
  isCardActivationEnabled: true,
  isCBREnabled: true,
  isCreditLimitIncreaseEnabled: true,
  isCobrowseEnabled: true,
  isDYEnabled: true,
  isDYScriptEnabled: true,
  isIvaEnabled: true,
  isLateFeeDateEnabled: false,
  isManagePaymentMethodsEnabled: true,
  isMarketingPrivacySettingEnabled: true,
  isProfileManagementEnabled: true,
  isProgressBarEnabled: true,
  isRewardsDeeplinkEnabled: true,
  isSavingCheckingEnabled: true,
  isSecuredInstallmentEnabled: true,
  isShowCookieSetting: true,
  isStatementsDeeplinkEnabled: true,
  isStatementsMfeEnabled: true,
  isSurflyScriptTagEnabled: true
}

export default NODE_ENV === 'production' ? prodFeatureFlags : localFeatureFlags
