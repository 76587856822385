import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faExternalLink,
  faCheck as fasCheck,
  faEllipsisH as fasEllipsisH,
  faExclamationCircle as fasExclamationCircle,
  faGift as fasGift,
  faTimes as fasTimes,
  faUserCog as fasUserCog
} from '@fortawesome/free-solid-svg-icons'
import {
  faAngleUp,
  faBell,
  faCalculatorAlt,
  faCalendarAlt,
  faCalendarExclamation,
  faChartPieAlt,
  faCheckCircle,
  faChevronDown,
  faChevronRight,
  faClock,
  faCreditCard,
  faCreditCardBlank,
  faDonate,
  faEllipsisH,
  faFileInvoice,
  faFileInvoiceDollar,
  faGlobe,
  faHandHoldingUsd,
  faHandsUsd,
  faListAlt,
  faMedal,
  faMoneyCheckAlt,
  faPercentage,
  faPhone,
  faPhoneLaptop,
  faReceipt,
  faStarChristmas,
  faUserCog,
  faWallet,
  faAngleDown as falAngleDown,
  faAngleLeft as falAngleLeft,
  faAngleRight as falAngleRight,
  faCloudRain as falCloudRain,
  faExclamationTriangle as falExclamationTriangle,
  faLink as falLink
} from '@fortawesome/pro-light-svg-icons'
import {
  faAngleDown,
  faAngleLeft,
  faBars,
  faCalendarDay,
  faCheck,
  faDollarSign,
  faExclamationCircle,
  faExclamationTriangle,
  faFileAlt,
  faGear,
  faInfoCircle,
  faLink,
  faMessagePen,
  faPen,
  faSignOutAlt,
  faSpinner,
  faSquareSlidersVertical,
  faStoreAltSlash,
  faSync,
  faSyncAlt,
  faTimes,
  faUndoAlt,
  faUniversity,
  faUsdCircle,
  faUser,
  faXmark,
  faAngleUp as farAngleUp
} from '@fortawesome/pro-regular-svg-icons'
import {
  faUserCircle,
  faChartPieAlt as fasChartPieAlt,
  faCreditCard as fasCreditCard,
  faFileInvoice as fasFileInvoice,
  faLink as fasLink,
  faListAlt as fasListAlt
} from '@fortawesome/pro-solid-svg-icons'

library.add(
  faAngleDown,
  faAngleLeft,
  faAngleUp,
  faAngleUp,
  faBars,
  faBell,
  faCalculatorAlt,
  faCalendarAlt,
  faCalendarDay,
  faCalendarExclamation,
  faChartPieAlt,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronRight,
  faClock,
  faCreditCard,
  faCreditCardBlank,
  faDollarSign,
  faDonate,
  faEllipsisH,
  faExclamationCircle,
  faExclamationTriangle,
  faFileAlt,
  faFileInvoice,
  faFileInvoiceDollar,
  faGear,
  faGlobe,
  faHandHoldingUsd,
  faHandsUsd,
  faInfoCircle,
  falAngleDown,
  falAngleLeft,
  falAngleRight,
  falCloudRain,
  falExclamationTriangle,
  faExternalLink,
  faLink,
  faListAlt,
  falLink,
  faMedal,
  faMessagePen,
  faMoneyCheckAlt,
  faPen,
  faPercentage,
  faPhone,
  faPhoneLaptop,
  farAngleUp,
  faReceipt,
  fasChartPieAlt,
  fasCheck,
  fasCreditCard,
  fasEllipsisH,
  fasExclamationCircle,
  fasFileInvoice,
  fasGift,
  faSignOutAlt,
  fasLink,
  fasListAlt,
  faSpinner,
  faSquareSlidersVertical,
  faStarChristmas,
  fasTimes,
  faStoreAltSlash,
  fasUserCog,
  faSync,
  faSyncAlt,
  faTimes,
  faUndoAlt,
  faUniversity,
  faUsdCircle,
  faUser,
  faUserCircle,
  faUserCog,
  faWallet,
  faXmark
)
